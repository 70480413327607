@font-face {
font-family: 'gilroyRegular';
src: url(/_next/static/media/1d2eb293e525c352-s.p.ttf) format('truetype');
font-display: block;
}@font-face {font-family: 'gilroyRegular Fallback';src: local("Arial");ascent-override: 74.23%;descent-override: 21.55%;line-gap-override: 19.16%;size-adjust: 104.41%
}.__className_44d45e {font-family: 'gilroyRegular', 'gilroyRegular Fallback'
}.__variable_44d45e {--font-gilroy-regular: 'gilroyRegular', 'gilroyRegular Fallback'
}

@font-face {
font-family: 'gilroyMedium';
src: url(/_next/static/media/6e3bb5ed373e306d-s.p.ttf) format('truetype');
font-display: block;
}@font-face {font-family: 'gilroyMedium Fallback';src: local("Arial");ascent-override: 73.73%;descent-override: 21.53%;line-gap-override: 20.29%;size-adjust: 104.97%
}.__className_fd7454 {font-family: 'gilroyMedium', 'gilroyMedium Fallback'
}.__variable_fd7454 {--font-gilroy-medium: 'gilroyMedium', 'gilroyMedium Fallback'
}

@font-face {
font-family: 'gilroySemiBold';
src: url(/_next/static/media/04e2f3d6f9e878bd-s.p.ttf) format('truetype');
font-display: block;
}@font-face {font-family: 'gilroySemiBold Fallback';src: local("Arial");ascent-override: 73.33%;descent-override: 21.41%;line-gap-override: 21.32%;size-adjust: 105.55%
}.__className_421594 {font-family: 'gilroySemiBold', 'gilroySemiBold Fallback'
}.__variable_421594 {--font-gilroy-semibold: 'gilroySemiBold', 'gilroySemiBold Fallback'
}

@font-face {
font-family: 'gilroyBold';
src: url(/_next/static/media/4a9ebf9ff0d4e103-s.p.ttf) format('truetype');
font-display: block;
}@font-face {font-family: 'gilroyBold Fallback';src: local("Arial");ascent-override: 72.87%;descent-override: 21.40%;line-gap-override: 22.44%;size-adjust: 106.07%
}.__className_f93fa9 {font-family: 'gilroyBold', 'gilroyBold Fallback'
}.__variable_f93fa9 {--font-gilroy-bold: 'gilroyBold', 'gilroyBold Fallback'
}

@font-face {
font-family: 'orange';
src: url(/_next/static/media/cfff1ace20854cc8-s.p.ttf) format('truetype');
font-display: block;
}@font-face {font-family: 'orange Fallback';src: local("Arial");ascent-override: 74.57%;descent-override: 24.86%;line-gap-override: 9.94%;size-adjust: 100.58%
}.__className_dedf73 {font-family: 'orange', 'orange Fallback'
}.__variable_dedf73 {--font-orange: 'orange', 'orange Fallback'
}

